import styled from "styled-components";
// import { NavHashLink } from 'react-router-hash-link';
import { Link } from "react-scroll";
import { RiArrowLeftSLine } from "react-icons/ri";
import { CgDetailsMore } from "react-icons/cg";

export const Container = styled.div`
  transition: all 0.2s;
  position: sticky;
  /* top: ${({ scrollNav }) => (scrollNav ? 57 : "-3px")}; */
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  /* width: 100vw; */
  height: 60px;
  overflow-x: scroll;
  background: black;
  overflow-y: hidden;

  /* background: ${(scrollNav) =>
    scrollNav
      ? "linear-gradient(45deg, rgba(255, 39, 229, 1), rgba(1, 12, 88, 1))"
      : "linear-gradient(45deg, rgba(255, 39, 229, 0), rgba(1, 12, 88, 0))"}; */
  z-index: 120;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 1500px) {
    justify-content: center;
    align-items: left;
    height: 100%;
    top: 0;
    left: ${({ $tocOpen }) => ($tocOpen ? 0 : "-100%")};
    width: 42vw;
    position: fixed;
    background-color: #000;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    width: 60vw;
  }
  @media only screen and (min-device-width: 360px) and (max-device-width: 768px) {
    width: 100vw;
  }
`;

export const Menu = styled.ul`
  display: flex;
  scroll-margin: none;
  text-align: left;
  /* margin-left: auto;
  margin-right: auto; */
  list-style: none;
  @media only screen and (max-width: 1500px) {
    flex-direction: column;
  }
`;

export const MoreIcon = styled(CgDetailsMore)`
  display: none;
  @media only screen and (max-width: 1500px) {
    display: ${({ $tocOpen }) => ($tocOpen ? "none" : "block")};
    position: fixed;
    cursor: pointer;
    top: 12px;
    height: 30px;
    width: 30px;
    left: 12px;
    color: white;
    font-size: 30px;
  }
`;
export const LeftIcon = styled(RiArrowLeftSLine)`
  /* display: none; */
  @media only screen and (max-width: 1500px) {
    display: ${({ $tocOpen }) => ($tocOpen ? "block" : "none")};
    position: fixed;
    height: 30px;
    width: 30px;
    top: 12px;
    left: 12px;
    color: white;
    transition: all 1.5s;
    font-size: 30px;
    cursor: pointer;
  }
`;

// export const Links = styled(NavHashLink)`
export const Links = styled(Link)`
  opacity: 0.3;
  transition: 200ms ease-in;
  cursor: pointer;
  margin: auto;
  margin-right: 0;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  height: 100%;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 12px;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    color: white;
  }
  &.active {
    opacity: 1;
    /* border-bottom: 1px solid white; */
    text-decoration-line: underline;
    text-decoration-color: #01bf71;
    text-decoration-thickness: 0.2rem;
  }
  @media only screen and (max-width: 1500px) {
    margin: 9px;
    font-size: 0.9rem;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 1.2rem;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 0.8rem;
  }
  @media only screen and (min-device-width: 360px) and (max-device-width: 768px) {
    font-size: 0.7rem;
  }
`;
