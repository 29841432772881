import styled from "styled-components";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

export const SidebarContainer = styled.aside`
  position: fixed;
  overflow-y: scroll;
  z-index: 99;
  width: 36%;
  height: 100vh;
  background: black;
  border-left: 2px solid #090909;
  display: grid;
  align-items: center;
  top: 0;
  right: 0;
  transition: 0.2s ease-in-out;
  opacity: ${({ $isOpen }) => ($isOpen ? "100%" : "0")};
  right: ${({ $isOpen }) => ($isOpen ? "0" : "-100%")};

  @media screen and (max-width: 1200px) {
    width: 60%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const SidebarWrapper = styled.div`
  color: #fff;
  @media screen and (min-width: 360px) and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const RightArrow = styled(RiArrowRightSLine)`
  color: #fff;
  position: absolute;
  right: 30px;
  top: 6px;
  /* margin-right: auto; */
  /* margin-left: 12px; */
  cursor: pointer;
  width: 36px;
  height: 36px;
  /* display: ${({ scrollNav }) => (scrollNav ? "block" : "none")}; */
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  @media only screen and (min-device-width: 1366px) and (max-device-width: 1500px) {
    right: 75px;
  }
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(11, 60px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(11, 36px);
  }
`;

export const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: left;
  font-size: 1.6rem;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarSubLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: right;
  font-size: 0.8rem;
  margin-left: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;
export const SidebarNavHashLink = styled(NavHashLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: right;
  font-size: 1.2rem;
  margin-left: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 360px) and (max-width: 760px) {
    max-width: 80%;
    align-items: center;
    flex-direction: column;
  }
`;

export const SidebarRoute = styled.button`
  border-radius: 50px;
  background: #9c20ad;
  white-space: nowrap;
  padding: 16px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  width: 60%;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #f126dc;
    color: #fff;
    font-weight: bold;
  }
`;
