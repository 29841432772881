import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const SecondaryNav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? 'black' : 'black')};
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 45px;
  z-index: 9;

  @media screen and (max-width: 1200px) {
    transition: 0.8s all ease;
  }
`;

export const SecondaryNavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 45px;
  z-index: 9;
  width: 100vw;
  padding: 0 21px;
`;

export const SecondaryNavMenu = styled.ul`
  display: flex;
  align-items: left;
  list-style: none;
  text-align: center;
  margin-bottom: 0;

  a {
    text-decoration: none;
  }

  li {
    height: 60px;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    height: 100%;
  }

  .current {
    li {
      border-bottom: 2px solid #01bf71;
      transition: 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
