import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  /* background: ${({ scrollNav }) => (scrollNav ? "black" : "transparent")}; */
  background: black;
  /* opacity: ${({ scrollNav }) => (scrollNav ? 1 : 0.5)}; */
  height: 60px;
  /* margin-top: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  /* top: ${({ scrollNav }) => (scrollNav ? -60 : "-3px")}; */
  top: ${({ scrollNav }) => (scrollNav ? -60 : "-3px")};
  display: ${({ leftDrawerOpen }) => (leftDrawerOpen ? "none" : "block")};
  z-index: 100;

  @media screen and (max-width: 1200px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  background-color: black;
  justify-content: center;
  height: 60px;
  width: 100vw;
  padding: 0 24px;
  z-index: 100;
`;

export const NavLogo = styled(Link)`
  color: #fff;
  position: absolute;
  cursor: pointer;
  font-size: 1.1rem;
  display: flex;
  left: 15px;
  top: 15px;
  text-decoration: none;
  text-transform: uppercase;
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-bottom: 0;

  a {
    text-decoration: none;
  }

  li {
    height: 60px;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    height: 100%;
  }

  .current {
    li {
      border-bottom: 3px solid #01bf71;
      transition: 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 1366px) {
    display: none;
  }
`;
