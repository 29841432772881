import React from 'react';
import PictureBackground from '../../components/PictureBackground';
import IMAGES_URL from '../../services/image.service';

const createIndices = (n, tag) => {
  let results = [];
  for (let i = 0; i <= n; i++) {
    results.push({
      index: i,
      id: tag + '-' + i,
    });
  }
  return results;
};

const images = createIndices(39, 'game-plan');

const Ibench = () => {
  return (
    <div className='scroll-snap-y '>
      <PictureBackground
        id='introduction'
        title=''
        desc=''
        descLink=''
        backgroundImg={IMAGES_URL + 'ibench.png'}
        leftBtnTxt=''
        leftBtnLink=''
        rightBtnTxt=''
        rightBtnLink=''
        noButtons
      />
      <div
        style={{
          height: '100vh',
          width: '100vw',
          backgroundColor: 'black',
          display: 'flex',
        }}
      >
        <iframe
          style={{
            margin: 'auto',
            scrollSnapAlign: 'center',
            overflow: 'hidden',
            overflowX: 'hidden',
            overflowY: 'hidden',
            height: '100%',
            width: '100%',
            // position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
          }}
          height='100%'
          width='100%'
          src='https://www.youtube.com/embed/usOT_2gLMf4/?autoplay=0&controls=1&mute=0&cc_load_policy=1&rel=0&modestbranding=1&autohide=1&showinfo=0&loop=0'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          backgroundColor: 'black',
          display: 'flex',
        }}
      >
        <iframe
          style={{
            margin: 'auto',
            scrollSnapAlign: 'center',
            overflow: 'hidden',
            overflowX: 'hidden',
            overflowY: 'hidden',
            height: '100%',
            width: '100%',
            // position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
          }}
          height='100%'
          width='100%'
          src='https://www.youtube.com/embed/1IcMq8tGK48/?autoplay=0&controls=1&mute=0&cc_load_policy=1&rel=0&modestbranding=1&autohide=1&showinfo=0&loop=0'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
      {images.map((image, i) => (
        <PictureBackground
          key={image.id}
          id={image.id}
          title=''
          desc=''
          descLink=''
          backgroundImg={IMAGES_URL + `ibench/game-plan/${image.index}.jpg`}
          leftBtnTxt=''
          leftBtnLink=''
          rightBtnTxt='LEARN MORE'
          rightBtnLink=''
          noButtons
        />
      ))}
    </div>
  );
};

export default Ibench;
