import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navigation: {
      scrollNav: false,
    },
  },
  reducers: {
    scrollNavTrue: (state) => {
      return {
        ...state,
        navigation: {
          scrollNav: true,
        },
      };
    },
    scrollNavFalse: (state) => {
      return {
        ...state,
        navigation: {
          scrollNav: false,
        },
      };
    },
    default: (state) => {
      return state;
    },
  },
});

export const { scrollNavTrue, scrollNavFalse } = navigationSlice.actions;

export const selectNavigation = (state) => state.navigation;

export default navigationSlice.reducer;
