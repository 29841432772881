import React from 'react';
import './Construction.css';
import { auth } from '../../firebase';

const Construction = () => {
  const backgroundImg =
    'https://kevin-jain-website-bucket.s3.us-east-2.amazonaws.com/static/img/unifying_humanity/book_proposal/bp_cover.png';

  const signOutUser = () => {
    auth.signOut();
  };

  return (
    <div
      className="construction"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${backgroundImg})`,
      }}
    >
      <>
        <div className="construction__content">
          <h3>This site is currently under construction.</h3>
          {/* <h3>This site is currently hidden while under construction.</h3>
          <h3>The curtains will reopen in the near future.</h3>
          <h3>
            For your convenience, please logout and check back with us soon!
          </h3>
          <div className='construction__button-wrapper'>
            <button
              onClick={() => {
                signOutUser();
              }}
              className='construction__button'
            >
              Logout
            </button>
          </div> */}
        </div>
      </>
    </div>
  );
};

export default Construction;
