import { createSlice } from '@reduxjs/toolkit';

export const toggleSlice = createSlice({
  name: 'sideBarOpen',
  initialState: {
    sideBarOpen: false,
  },
  reducers: {
    toggleSideBar: (state) => {
      return {
        sideBarOpen: !state.sideBarOpen,
      };
    },
    default: (state) => {
      return state;
    },
  },
});

export const { toggleSideBar } = toggleSlice.actions;

export const selectToggle = (state) => state.sideBarOpen;

export default toggleSlice.reducer;
