import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 360px) and (max-width: 780px) {
    background-position: top;
  }
`;

export const Form = styled.form`
  margin: auto;
  /* padding: 60px; */
  /* border: 1px solid darkgray; */
  max-width: 1200px;
  max-height: 1200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 360px) and (max-width: 780px) {
    width: 75vw;
  }
`;

export const Input = styled.input`
  outline-width: 0;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #9c20ad;
  color: whitesmoke;
  height: 60px;
  width: 100%;
  max-width: 1000px;
  font-size: 24px;
  margin-top: 12%;
  font-family: "Roboto", sans-serif;

  &:focus {
    border-bottom: 3px solid #f126dc;
    transition: all 0.2s;
  }
  @media screen and (min-width: 360px) and (max-width: 780px) {
    width: 100%;
    font-size: 18px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  max-width: 1000px;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: #9c20ad;
  outline: none;
  border: none;
  height: 60px;
  width: 400px;
  margin-top: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 0.85;
  color: whitesmoke;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;

  &:hover {
    background-color: #f126dc;
    transition: all 0.2s;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }
  &:active {
    background-color: #f126dc;
  }
  @media screen and (min-width: 360px) and (max-width: 780px) {
    width: 100%;
    font-size: 1.1rem;
    height: 60px;
  }
`;
