import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  justify-content: center;
  align-content: center;
  background: ${({ reverse }) =>
    reverse
      ? `linear-gradient(to right, #000, #050036, #050036, #050036, #000);`
      : "black"};
  z-index: 10;
  /* opacity: ${({ isVisible }) => (isVisible ? 1 : 0.25)}; */
  filter: ${({ sideBarOpen }) =>
    sideBarOpen ? "brightness(50%)" : "brightness(100%)"};

  @media screen and (max-width: 1550px) {
    transition: 0.8s all ease;
    object-fit: contain;
    background-size: contain;
    background: black;
  }
`;

export const ImageContainer = styled.div`
  height: 75%;
  width: 100%;
  display: flex;
  /* margin-top: ${({ reverse }) => (reverse ? "60px" : "0px")}; */
  align-items: center;
  object-fit: contain;
  overflow-y: visible;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: ${({ reverse }) => (reverse ? "0px" : "90px")};
  padding-top: ${({ reverse }) => (reverse ? "60px" : "0px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  object-fit: contain;
  background: ${({ sampleChapter1 }) =>
    sampleChapter1
      ? `linear-gradient(to right, #000, #050036, #050036, #050036, #000);`
      : "black"};
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    margin-top: 60px;
    text-align: center;
    background: black;
    padding-top: 0px;
  }
`;

export const TextBackground = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  display: ${({ sample }) => (sample ? "none" : "")};
  background: linear-gradient(to right, #000, #050036, #050036, #050036, #000);
  z-index: 10;
  /* margin-bottom: 60px; */
  /* opacity: ${({ isVisible }) => (isVisible ? 1 : 0.25)}; */

  filter: ${({ sideBarOpen }) =>
    sideBarOpen ? "brightness(50%)" : "brightness(100%)"};
  @media screen and (max-width: 1550px) {
    transition: 0.8s all ease;
    object-fit: contain;
    background-size: contain;
  }
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    background: black;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 840px;
  object-fit: contain;
  overflow-y: hidden;
  margin-bottom: 90px;
  margin-top: 30px;
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    max-width: 80vw;
    overflow-x: wrap;
  }
  @media screen and (min-width: 737) and (max-width: 1200px) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }
  /* ----------- iPad 1, 2, Mini and Air ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }

  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }
  /* Portrait iPad Air New */
  @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }

  /* Landscape iPad Air New */
  @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }
  /* ----------- iPad Pro 10.5" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }

  /* ----------- iPad Pro 12.9" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 80vw;
    padding-left: 12%;
    padding-right: 12%;
  }
`;

export const Title = styled.h1`
  color: whitesmoke;
  width: 100%;
  align-items: center;
  font-weight: 100;
  font-size: 39px;
  font-family: Arial, Helvetica, sans-serif;
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    font-size: 27px;
    max-width: 85vw;
    /* margin-left: 6%;
    margin-right: 6%; */
  }
`;

export const QuoteWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 840px;
  /* object-fit: contain; */
  overflow-y: hidden;
  margin-bottom: 30px;
  /* margin-top: 30px; */
  @media only screen and (min-width: 360px) and (max-device-width: 780px) {
    /* overflow-x: wrap; */
    max-width: 75%;
    /* flex-direction: row; */
  }
  @media screen and (min-width: 737) and (max-width: 1200px) {
    padding-left: 12%;
    padding-right: 12%;
  }
`;

export const AppendixWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 840px; */
  /* object-fit: contain; */
  overflow-y: hidden;
  margin-bottom: 12px;
  /* margin-top: 30px; */
  @media only screen and (min-width: 360px) and (max-device-width: 780px) {
    /* overflow-x: wrap; */
    max-width: 75%;
    /* flex-direction: row; */
  }
  @media screen and (min-width: 737) and (max-width: 1200px) {
    padding-left: 12%;
    padding-right: 12%;
  }
`;

export const Quote = styled.p`
  color: whitesmoke;
  font-style: italic;
  text-align: center;
  font-weight: 100;
  font-size: 21px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 12px;
  line-height: 1.5em;
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    font-size: 18px;
    text-align: center;
  }
`;

export const AppendixText = styled.p`
  color: whitesmoke;
  /* font-style: italic; */
  text-align: center;
  font-weight: 100;
  font-size: 21px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 12px;
  line-height: 1.5em;
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    font-size: 18px;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 360px) and (max-width: 780px) {
    flex-direction: column;
    /* margin-bottom: 1.1rem; */
  }
  @media only screen and (max-device-width: 1500px) {
    flex-direction: column;
  }
`;
export const Author = styled.h3`
  color: whitesmoke;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 21px;
  font-weight: 100;
  margin-top: 9px;
  margin-bottom: 1.1rem;
  text-align: center;
  @media only screen and (min-width: 390px) and (max-width: 780px) {
    font-size: 18px;
    margin-bottom: 6px;
  }
  @media only screen and (max-device-width: 1500px) {
    margin-bottom: 0px;
  }
`;
export const Source = styled.h3`
  color: whitesmoke;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 21px;
  /* text-align: right; */
  font-weight: 100;
  margin-top: 9px;
  margin-bottom: 1.1rem;
  font-style: italic;
  margin-left: 6px;
  @media only screen and (min-width: 390px) and (max-width: 780px) {
    font-size: 18px;
    text-align: center;
    margin-top: 6px;
  }
  @media only screen and (max-device-width: 1500px) {
    margin-top: 6px;
  }
`;

export const Text = styled.div`
  color: lightgray;
  font-size: 21px;
  margin-top: 24px;
  line-height: 1.5em;
  /* text-align: left; */
  font-family: Arial, Helvetica, sans-serif;
  @media only screen and (min-width: 390px) and (max-width: 780px) {
    font-size: 18px;
  }
`;

export const ItemLowerThird = styled.div`
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
`;

export const ItemButtons = styled.div`
  display: flex;
`;

export const ItemExpand = styled.div`
  color: white;
  width: 100%;
  margin-top: 3%;
  text-align: center;
`;
