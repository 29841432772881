import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBq63S7mZvHkCIljZfAqB6gmx4mNtCtJj0',
  authDomain: 'kevin-jain-react.firebaseapp.com',
  projectId: 'kevin-jain-react',
  storageBucket: 'kevin-jain-react.appspot.com',
  messagingSenderId: '886781099898',
  appId: '1:886781099898:web:454cb51728f2d40bbf20c3',
  measurementId: 'G-SJ8W6SSD28',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth };
export default db;
