import { createSlice } from "@reduxjs/toolkit";

export const leftDrawerSlice = createSlice({
  name: "leftDrawerOpen",
  initialState: { leftDrawerOpen: false },
  reducers: {
    toggleLeftDrawer: (state) => {
      return {
        ...state,
        leftDrawerOpen: !state.leftDrawerOpen,
      };
    },
    default: (state) => {
      return state;
    },
  },
});

export const { toggleLeftDrawer } = leftDrawerSlice.actions;

export const selectLeftDrawer = (state) => state.leftDrawerOpen;

export default leftDrawerSlice.reducer;
