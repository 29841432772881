import React, { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
// import HomePage from "./pages/index";
import Ibench from './views/Ibench';
import IndiaToGo from './views/IndiaToGo';
import KidsPage from './pages/kids.page';
import Login from './views/Login';
// import AdminPage from './pages/admin.page';
import LoadingScreen from './components/LoadingScreen';
import { auth } from './firebase';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, selectUser } from './redux/userSlice';
import {
  setLoadingTrue,
  setLoadingFalse,
  selectLoadingState,
} from './redux/loadingSlice';
// import Book from "./views/Book/Book";
import BookProposal from './views/BookProposal';
import JainCapital from './views/JainCapital';
import Query from './views/Query';
import Author from './views/Author';
import Construction from './views/Construction';
import Robots from './views/Robots';
import userUID from './userList';

const App = () => {
  const isUnderConstruction = true;
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingState);
  const dispatch = useDispatch();

  const [content, setContent] = useState({});
  // const [showAdminPage, setShowAdminPage] = useState(false);

  const history = useHistory();

  useEffect(() => {
    dispatch(setLoadingTrue(true));
    setTimeout(() => {
      const unsubscribe = auth.onAuthStateChanged((userAuth) => {
        if (userAuth) {
          dispatch(
            login({
              uid: userAuth.uid,
              email: userAuth.email,
            })
          );
        } else {
          dispatch(logout());
        }
        dispatch(setLoadingFalse(false));
      });
      return unsubscribe;
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    fetch('/db.json')
      .then((res) => res.json())
      .then((data) => {
        setContent(data);
      });
  }, []);
  if (isUnderConstruction) {
    return <Construction />;
  } else {
    return (
      <>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Router history={history}>
            {!user ? (
              <Login />
            ) : userUID.includes(user.uid) ? (
              <>
                <Sidebar />
                <Navbar data={content} />
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/unifying_america#overview" />
                  </Route>
                  <Route exact path="/unifying_america/query">
                    <Query />
                  </Route>
                  <Route exact path="/unifying_america">
                    <BookProposal data={content} />
                  </Route>
                  {/* <Route exact path="/unifying_america/book">
                  <Book />
                </Route> */}
                  <Route exact path="/unifying_america/author">
                    <Author />
                  </Route>
                  <Route exact path="/unifying_humanity/robots">
                    <Robots data={content} />
                  </Route>
                  <Route path="/unifying_humanity/kids" component={KidsPage} />
                  <Route exact path="/ibench" component={Ibench} />
                  <Route exact path="/india_to_go">
                    <IndiaToGo data={content} />
                  </Route>
                  <Route exact path="/jain_capital">
                    <JainCapital data={content} />
                  </Route>

                  {/* <Route exact path='/admin'>
                  <AdminPage />
                </Route> */}
                </Switch>
              </>
            ) : (
              <Construction />
            )}
          </Router>
        )}
      </>
    );
  }
};

export default App;
