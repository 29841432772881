import { createSlice } from '@reduxjs/toolkit';

export const stickyTocSlice = createSlice({
  name: 'stickyToc',
  initialState: {
    stickyTocIsVisible: false,
  },
  reducers: {
    stickyTocShow: (state) => {
      return {
        ...state,
        stickyTocIsVisible: true,
      };
    },
    stickyTocHide: (state) => {
      return {
        ...state,

        stickyTocIsVisible: false,
      };
    },
    default: (state) => {
      return state;
    },
  },
});

export const { stickyTocShow, stickyTocHide } = stickyTocSlice.actions;

export const selectStickyToc = (state) => state.stickyToc;

export default stickyTocSlice.reducer;
