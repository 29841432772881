import styled from "styled-components";
import { Link } from "react-scroll";
import { NavHashLink } from "react-router-hash-link";
// import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { CgDetailsMore } from "react-icons/cg";

export const LeftDrawerContainer = styled.aside`
  position: fixed;
  /* overflow-y: hidden; */
  z-index: 120;
  height: 100vh;
  width: 36%;
  background: black;
  border-right: 2px solid #090909;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.2s ease-in-out;
  left: ${({ $isOpen }) => ($isOpen ? 0 : "-37%")};

  @media screen and (max-width: 1200px) {
    width: 60%;
    left: ${({ $isOpen }) => ($isOpen ? 0 : "-61%")};
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    left: ${({ $isOpen }) => ($isOpen ? 0 : "-100%")};
  }
`;

export const MoreIcon = styled(CgDetailsMore)`
  /* display: none; */
  display: ${({ $isOpen }) => ($isOpen ? "none" : "block")};
  position: fixed;
  cursor: pointer;
  top: 12px;
  height: 30px;
  width: 30px;
  left: 12px;
  color: white;
  font-size: 30px;

  /* @media only screen and (max-width: 1366px) {
    display: ${({ $isOpen }) => ($isOpen ? "none" : "block")};
    position: fixed;
    cursor: pointer;
    top: 12px;
    height: 30px;
    width: 30px;
    left: 12px;
    color: white;
    font-size: 30px;
  } */
`;
export const LeftIcon = styled(RiArrowLeftSLine)`
  /* display: none; */
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  position: absolute;
  z-index: 100;
  height: 30px;
  width: 30px;
  top: 12px;
  left: 12px;
  color: white;
  transition: all 1.5s;
  font-size: 30px;
  cursor: pointer;

  /* @media only screen and (max-width: 1500px) {
    display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
    position: fixed;
    height: 30px;
    width: 30px;
    top: 12px;
    left: 12px;
    color: white;
    transition: all 1.5s;
    font-size: 30px;
    cursor: pointer;
  } */
`;

export const LeftDrawerWrapper = styled.div`
  color: #fff;
  @media screen and (min-width: 360px) and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftDrawerMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(22, 36px);
  text-align: center;
  overflow-y: scroll;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(22, 18px);
  }
`;

export const LeftDrawerLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: left;
  font-size: 0.9rem;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
  /* @media screen and (max-width: 1200px) {
    font-size: 1rem;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.8rem;
  } */
`;

export const LeftDrawerSubLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: right;
  font-size: 0.8rem;
  margin-left: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;
export const LeftDrawerNavHashLink = styled(NavHashLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: right;
  font-size: 0.9rem;
  margin-left: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;

export const LeftDrawerBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 360px) and (max-width: 760px) {
    max-width: 80%;
    align-items: center;
    flex-direction: column;
  }
`;

export const LeftDrawerButton = styled.button`
  border-radius: 50px;
  background: #9c20ad;
  white-space: nowrap;
  padding: 16px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #f126dc;
    color: #fff;
    font-weight: bold;
  }
`;
