import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./redux/userSlice";
import loadingReducer from "./redux/loadingSlice";
import navigationReducer from "./redux/navigationSlice";
import toggleReducer from "./redux/toggleSlice";
import stickyTocReducer from "./redux/stickyTocSlice";
import leftDrawerReducer from "./redux/leftDrawerSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    loading: loadingReducer,
    navigation: navigationReducer,
    sideBarOpen: toggleReducer,
    stickyToc: stickyTocReducer,
    leftDrawerOpen: leftDrawerReducer,
  },
});
