import styled from 'styled-components';

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-color: black;
  justify-content: center;
  scroll-snap-align: start;

  filter: ${({ sideBarOpen }) =>
    sideBarOpen ? 'brightness(50%)' : 'brightness(100%)'};
  @media screen and (max-width: 1550px) {
    transition: 0.8s all ease;
    object-fit: contain;
    background-size: contain;
  }
`;

export const Container = styled.div`
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  font-size: 34px;
  color: #fff;
  align-items: center;
  flex: 1;
`;

export const Description = styled.p`
  font-weight: 200;
  font-size: 14px;
`;

export const LowerThird = styled.div`
  margin-bottom: 5%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 12%;
`;

export const Expand = styled.div`
  color: white;
  width: 100%;
  margin-top: 3%;
  text-align: center;
`;
